import styled from 'styled-components'
import iPhoneFramePng from '../../assets/images/iphone-frame.png'

export const IphoneFrameContainer = styled.div`
  position: absolute;
  z-index: 2;
`

export const IphoneFrame = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url(${iPhoneFramePng});
  width: 474px; //430px viewable x-screen
  height: 970px; // 932px viewable y-screen
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  
`

export const ContentContainerOutter = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 432px;
  height: 936px;
  z-index: 3;
  border-radius: 62px;
  overflow: hidden;
`

export const ContentContainerInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 40px 20px 20px 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #232323;
`
