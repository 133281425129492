import { useEffect, useState } from "react";
import { SocialIcon } from 'react-social-icons';
import useSound from 'use-sound';
import { AnimatePresence, motion } from "framer-motion";

import Camera from "../../components/Camera";
import Loader from '../../components/Loader';

import { 
    Page,
    ImageBg,
    Logo,
    LogoShort,
    InputGroup,
    FormGroup,
    StyledInput,
    Border, 
    BorderLink,
    Disk,
    Headphones,
    CameraFrameContainer,
    Flash,
    FrontCamera
} from "./styles";

import bg_validacao from '../../assets/images/bg_validacao.png'
import bg_foto_personalizada from '../../assets/images/bg_foto_personalizada.png'
import front_camera from '../../assets/images/front-camera.png'
import cd from '../../assets/images/CD.png'
import headphones from '../../assets/images/fone.png'
import logo from '../../assets/images/logo.png'
import logo_short from '../../assets/images/logo_short.png'

import users from '../../assets/files/users.json';

import flashSound from '../../assets/sounds/cameraflash.mp3';

const Home = () => {
    const [playFlash] = useSound(flashSound);

    const [instagram, setInstagram] = useState("")
    const [user, setUser] = useState(null)
    const [invalid, setInvalid] = useState(false)
    const [bg, setBg] = useState("validacao")
    const [isFlash, setIsFlash] = useState(false)
    const [isFlashing, setIsFlashing] = useState(false)
    const [takePicture, setTakePicture] = useState("out")
    const [rotateLogo, setRotateLogo] = useState(false)
    const [rotationState, setRotationState] = useState(0);
    const [loading, setLoading] = useState(true);

    const access = [...users]

    const variantsHeadphone = {
        start: { x: 140, y: 100 },
        end: { x: 140, y: 170, rotate: -14},
      }

    const variantsCamera = {
        start: { x: 300, y: 1000, width: "450px", height: "450px" },
        end: { x: 300, y: 325, width: "450px", height: "450px" },
    }

    const variantsFrontCamera = {
        out: { x: 400, y: -20},
        enter: { x: 0, y: -20},
        leave: { x: 400, y: -20 },
    }
    
    function startRotation() {
        setInterval(() => {
            setRotationState(rotationState + 360) 
        }, 2000);
      }

    const submit = () => {
        let currentUser = access.find(obj => Object.values(obj).includes(instagram))
        // console.log(currentUser)
        if (currentUser) {
            setUser(currentUser)
            localStorage.setItem("valid", "true");
            localStorage.setItem("user", JSON.stringify(currentUser));
        } else {
            setInvalid(true)
            setInstagram("")
            setTimeout(() => {setInvalid(false)}, 500)
        }
    }   

    useEffect(() => {
		const timer = setInterval(() => {
            setLoading(false);
        }, 500);

        return () => {
            clearInterval(timer);
        };
	}, [])
    
    useEffect(() => {
        if (user) {
            setRotationState(rotationState + 360)
            startRotation();
        }
    }, [user]);

    useEffect(() => {
        let cachedUser = JSON.parse(localStorage.getItem("user"))
        if (cachedUser) {
            setInstagram(cachedUser.instagram)
        }
    }, [])

    useEffect(() => {
        if (user) {
            setBg("foto_personalizada")
        }
    }, [user])

    useEffect(() => {
        if (user) {
            setTakePicture("enter")
            setRotateLogo(true)
            
            setTimeout(() => {
                setIsFlash(true)
                setIsFlashing(true)
                playFlash()
            }, 800)

            setTimeout(() => {
                setIsFlash(false)
            }, 1800)

            setTimeout(() => {
                setIsFlashing(false)
            }, 1000)

            setTimeout(() => {
                setTakePicture("leave")
            }, 1500)
        }
    }, [user])

    return (
    <>
     {loading && 
     <Loader 
        strokeWidth="10"
        scale="2"
        radiusScale="1"
     />}
     <Page 
        bg={bg}
        initial={{opacity:0}} 
        animate={{opacity:1}} 
        transition={{duration:0.5, delay: 0.5}}
    >

        <ImageBg src={bg_validacao} opacity={bg === "validacao" ? "1" : "0"} duration={500}/>
        <ImageBg src={bg_foto_personalizada} opacity={bg === "validacao" ? "0" : "1"} duration={200}/>
        
        <Logo src={logo} rotateLogo={rotateLogo}/>
        <LogoShort src={logo_short} rotateLogo={!rotateLogo}/>
        
        <AnimatePresence exitBeforeEnter={true} initial={false} style={{height: "50%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
        {
        !user ?
        <motion.div key="phone-form" initial={{ x: -300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -300, opacity: 0 }} transition={{ duration: 0.2 }} style={{display: "flex", flexDirection: "column", alignItems: "center", position:"absolute", top:"40%"}}>
            <InputGroup>
                <FormGroup shake={invalid}>
                    <span>
                        <SocialIcon network="instagram" style={{ height: 25, width: 25, marginLeft: "4px" }} bgColor="#4F1F9E"/>
                    </span>
                    
                    <StyledInput id="instagram" name="instagram" type="text" placeholder="ex.: @gandaya.io" value={
                        instagram.length > 0 ? "@ " + instagram : ""
                    } onChange={(e) => setInstagram(e.target.value.replace("@", "").replace(" ", "").toLowerCase())}/>
                </FormGroup>
            </InputGroup>
            <Border style={{
                display: "block",
                fontSize: "1.5em",
                marginTop: "0.83em",
                marginBottom: "0.83em",
                marginLeft: "0",
                marginRight: "0",
                fontWeight: "bold",
                color: "#FFF",
                zIndex: "10",
                cursor: "pointer"
            }} className="myoutlinedtext" onClick={submit}>{"<< PLAY >>"}</Border>
        </motion.div>
        :
        <motion.div key="welcome" initial={{ x: 300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: 300, opacity: 0 }} transition={{ duration: 0.2 }} style={{width: "80%", maxWidth: "500px", display: "flex", marginBottom:"100px", flexDirection: "column", alignItems:"center", position:"absolute", top:"35%"}}>
            <span style={{ zIndex: "2", fontSize: "1.4rem", color: "#4F1F9E", "textAlign": "center"}}>HELLO PLAYER *-*</span>
            <Border style={{
                display: "block",
                fontSize: "2em",
                margin: "0.4em 0 0 0",
                fontWeight: "bold",
                position: "relative", zIndex: "2",
                color: "#FFF",
                textAlign: "center"
            }}>{user?.name.toUpperCase()}</Border>
            <div style={{display: "flex", alignItems: "flex-end", justifyContent: "space-between"}}>
            <BorderLink to="/info">{"<< LOGAR >>"}</BorderLink>
            </div>
        </motion.div>
        }
        </AnimatePresence>
        {!user ? (<motion.div
            key="cd"
            style={{
                position: "absolute",
                left: "-100px",
                bottom: "-10px"
            }}
            >
            <Disk src={cd}/>
        </motion.div>) : (
            <motion.div
            key="cd"
            animate={{rotate: rotationState}}
            transition={{ duration: 2, loop: Infinity, ease: "linear", }}
            style={{
                position: "absolute",
                left: "-100px",
                bottom: "-10px",
                zIndex: '0'
            }}
            >
            <Disk src={cd}/>
        </motion.div>
        )}
        <motion.div
            key="headphone"
            animate={user ? "end" : "start"}
            initial={{ x: 140, y: 100 }}
            variants={variantsHeadphone}
            transition={{ duration: 0.2 }}
            style={{
                position: "absolute",
                bottom: "0",
                zIndex: '0'
            }}
            >
            <Headphones src={headphones}/>
        </motion.div>
        <motion.div
            key="camera"
            animate={takePicture === "leave" ? "end" : "start"}
            initial={{ x: 300, y: 1000 }}
            variants={variantsCamera}
            transition={{ duration: 0.4 }}
            style={{
                position: "absolute",
                bottom: "10px"
            }}
            >
            <CameraFrameContainer>
                {user?.file_name && <Camera pic_url={`https://gandaya.b-cdn.net/replay-profile-pics/${user.file_name}`}/>}
            </CameraFrameContainer>
        </motion.div>
        {isFlash && <Flash flash={isFlashing}/>}
        <motion.div
            key="front-camera"
            animate={takePicture}
            initial={{ x: 400, y: -20 }}
            variants={variantsFrontCamera}
            transition={{ duration: 0.4 }}
            style={{
                position: "absolute",
                top: "0",
            }}
            >
            <FrontCamera src={front_camera}/>
        </motion.div>

    </Page>
    </>
    )
}

export default Home;