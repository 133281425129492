import styled from 'styled-components'

import camera from '../../assets/images/camera.png'

export const CameraFrame = styled.div`
  width: 300px;
  height: 184px;

  transform: translate(-50%, -50%);
  transform: rotate(15deg);

  background-image: url(${camera});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export const PicContainer = styled.div`
  position: absolute;
  width: 202px;
  height: 150px;

  left: 19px;
  top: 20px;

  border-radius: 5px;

  overflow: hidden;
`

export const Picture = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
`