import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  overflow: scroll;
`

export const BackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -2;

  transition: src 0.5s ease-in-out;
`

export const Play = styled.img`
  width: 90%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;

  opacity: 0.5;
`

export const Logo = styled.img`
  width: 75%;
  height: auto;
`

export const Text = styled.p`
  text-align: center;

  font-size: 1.2rem;
  font-family: Neuron;

  padding: 0 10%;

  text-shadow: 3px 0px 0 #4F1F9E,
  2.837451725101904px 0.9740984076140504px 0 #4F1F9E,
  2.3674215281891806px 1.8426381380690033px 0 #4F1F9E,
  1.6408444743672808px 2.5114994347875856px 0 #4F1F9E,
  0.7364564614223977px 2.908200797817991px 0 #4F1F9E,
  -0.24773803641699682px 2.9897534790200098px 0 #4F1F9E,
  -1.2050862739589083px 2.747319979965172px 0 #4F1F9E,
  -2.0318447148772227px 2.207171732019395px 0 #4F1F9E,
  -2.638421253619467px 1.427842179111221px 0 #4F1F9E,
  -2.959083910208167px 0.4937837708422021px 0 #4F1F9E,
  -2.959083910208167px -0.4937837708422014px 0 #4F1F9E,
  -2.6384212536194678px -1.4278421791112192px 0 #4F1F9E,
  -2.031844714877223px -2.2071717320193946px 0 #4F1F9E,
  -1.2050862739589072px -2.747319979965173px 0 #4F1F9E,
  -0.2477380364169982px -2.9897534790200098px 0 #4F1F9E,
  0.7364564614223964px -2.9082007978179916px 0 #4F1F9E,
  1.6408444743672796px -2.511499434787586px 0 #4F1F9E,
  2.3674215281891815px -1.842638138069002px 0 #4F1F9E,
  2.837451725101904px -0.9740984076140512px 0 #4F1F9E;
`

export const Button = styled(Link)`
  padding: 5px 15px;

  font-size: 1.3rem;
  font-family: CyberAngel;

  background-color: #18C9F3;
  color: #fff;

  border-radius: 15px;
  border: 2px solid #4F1F9E;
  filter: drop-shadow(2px 2px 0 #4F1F9E);

  margin-top: 20px;

  text-decoration: none;

  text-shadow: 3px 0px 0 #4F1F9E,
  2.837451725101904px 0.9740984076140504px 0 #4F1F9E,
  2.3674215281891806px 1.8426381380690033px 0 #4F1F9E,
  1.6408444743672808px 2.5114994347875856px 0 #4F1F9E,
  0.7364564614223977px 2.908200797817991px 0 #4F1F9E,
  -0.24773803641699682px 2.9897534790200098px 0 #4F1F9E,
  -1.2050862739589083px 2.747319979965172px 0 #4F1F9E,
  -2.0318447148772227px 2.207171732019395px 0 #4F1F9E,
  -2.638421253619467px 1.427842179111221px 0 #4F1F9E,
  -2.959083910208167px 0.4937837708422021px 0 #4F1F9E,
  -2.959083910208167px -0.4937837708422014px 0 #4F1F9E,
  -2.6384212536194678px -1.4278421791112192px 0 #4F1F9E,
  -2.031844714877223px -2.2071717320193946px 0 #4F1F9E,
  -1.2050862739589072px -2.747319979965173px 0 #4F1F9E,
  -0.2477380364169982px -2.9897534790200098px 0 #4F1F9E,
  0.7364564614223964px -2.9082007978179916px 0 #4F1F9E,
  1.6408444743672796px -2.511499434787586px 0 #4F1F9E,
  2.3674215281891815px -1.842638138069002px 0 #4F1F9E,
  2.837451725101904px -0.9740984076140512px 0 #4F1F9E;
`

export const Tickets = styled.img`
  width: 60%;
`