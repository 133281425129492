import { useState, useEffect } from 'react'

import {
  IphoneFrameContainer,
  ContentContainerOutter,
  ContentContainerInner,
  IphoneFrame,
} from './styles'

function IphoneFrameMask({ children }) {

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(window.innerWidth < 474 || window.innerHeight < 970);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 474 || window.innerHeight < 970);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {!isMobile ? (
      <IphoneFrameContainer>
        <IphoneFrame />
        <ContentContainerOutter>
          <ContentContainerInner>{children}</ContentContainerInner>
        </ContentContainerOutter>
      </IphoneFrameContainer>
      ) : children}
    </>
  )
}

export default IphoneFrameMask
