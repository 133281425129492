import { useEffect, useState } from 'react';
import Barcode from 'react-barcode'
import { motion as m} from "framer-motion";

import {
  Container,
  InfoContainer,
  BackgroundImage,
  Tickets,
  Play,
  Fire,
  TicketContainer,
  ShortLogo,
  Title,
  Text,
  TextContainer,
  TextInfoContainer,
  BarcodeContainer,
  TextBarCode,
  AccentE,
  TitleContainer,
  AccentC
} from './styles';

// Assets
import background from '../../assets/images/bg_ingresso.png';
import tickets from '../../assets/images/tickets.png'
import play from '../../assets/images/play.png'
import whitefire from '../../assets/images/whitefire.png'
import shortlogo from '../../assets/images/logo_short.png'



function Ticket(){

  const [user, setUser] = useState({})

  useEffect(() => {
    let cachedUser = JSON.parse(localStorage.getItem("user"))
    setUser(cachedUser)
  }, [])

  const barcodeOptions = {
    width: 3,
    height: 300,
    format: 'CODE128',
    displayValue: false,
    fontOptions: '',
    font: 'monospace',
    textAlign: 'center',
    textPosition: 'bottom',
    textMargin: 2,
    fontSize: 12,
    background: '#ffffff',
    lineColor: '#000000',
  };

  return(
    <m.div
      initial={{opacity:0}} 
      animate={{opacity:1}} 
      transition={{duration:0.75}}
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <BackgroundImage src={background}/>
      <Tickets src={tickets}/>
      <Container>
        <Play src={play}/>
        <Fire src={whitefire}/>

        <InfoContainer>
          <ShortLogo src={shortlogo}/>

          <TextInfoContainer>
            <TitleContainer>
              <Title>PRÉ EVENTO DE LANÇAMENTO</Title>
              <AccentE>´</AccentE>
              <AccentC>´</AccentC>
            </TitleContainer>

            <TextContainer>
              <Text color="#4F1F9E">DATA:</Text>
              <Text>27/07</Text>
            </TextContainer>

            <TextContainer>
              <Text color="#4F1F9E">HORÁRIO:</Text>
              <Text>20h</Text>
            </TextContainer>

            <TextContainer>
              <Text color="#4F1F9E">LOCAL:</Text>
              <Text>Scheeeins House</Text>
            </TextContainer>

            <Text>Rua Taques Alvim, 495</Text>
            <Text fontSize="0.9rem">Cidade Jardim, São Paulo - SP</Text>
          </TextInfoContainer>

        </InfoContainer>
        <TicketContainer>
          <Text 
            color="#000" 
            fontSize="0.8rem"
            style={{marginTop:"5px", marginBottom:"5px"}}
          >
            *APRESENTAR ESTE INGRESSO NA ENTRADA DO EVENTO
          </Text>
          <BarcodeContainer>
            {user && <Barcode 
              value={user.instagram} 
              {...barcodeOptions}
              />}
          </BarcodeContainer>
          <TextBarCode>
            34CK T0 TH3 2000's
          </TextBarCode>
        </TicketContainer>
      </Container>
    </m.div>
  )
}

export default Ticket