import { Link } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'
import { motion } from 'framer-motion'

const Page = styled(motion.div)`
    position: absolute;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    color: black;
    font-weight: 700;

    overflow: hidden;
`

const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;

    z-index: -1;

    opacity: ${props => props.opacity};
    transition: opacity ${props => props.duration}ms ease-in-out;
`

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

const Logo = styled.img`
    width: calc(192px * 01.5);
    height: calc(108px * 01.5);

    position: absolute;
    top: 10%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    -webkit-transition: transform 1s ease-in;
    -moz-transition: transform 1s ease-in;
    -ms-transition: transform 1s ease-in;
    -o-transition: transform 1s ease-in;
    transition: transform 1s ease-in;

    ${props =>
    props.rotateLogo &&
    css`
      transform: translateX(-1px) rotateY(90deg);
      -ms-transform: translateX(-1px) rotateY(90deg);
      -webkit-transform: translateX(-1px) rotateY(90deg);
    `}
`

const LogoShort = styled.img`
    width: 140px;
    position: absolute;
    top: 10%;

    -webkit-transition: transform 1s ease-out;
    -moz-transition: transform 1s ease-out;
    -ms-transition: transform 1s ease-out;
    -o-transition: transform 1s ease-out;
    transition: transform 1s ease-out 1s;

    ${props =>
    props.rotateLogo &&
    css`
      transform: translateX(-1px) rotateY(-90deg);
      -ms-transform: translateX(-1px) rotateY(-90deg);
      -webkit-transform: translateX(-1px) rotateY(-90deg);
      /* transition: transform 0.3s ease-in-out 1s; */
    `}
`

const InputGroup = styled.div`
    margin: 10px 0px;  
    width: 100%;
`

const FormGroup = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    & > span {
        white-space: nowrap;
        display: block;
        
        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }
        &:first-child {
            border-radius: 40px 0 0 40px;
        }
        &:last-child {
            border-radius: 0 40px 40px 0;
        }
        &:not(:first-child) {
            margin-left: -1px;
        }
    }
    & > span {
        text-align: center;
        padding: 8px 12px;
        font-size: 14px;
        line-height: 25px;
        color: #99a3ba;
        background: #fff;
        border: 1px solid #cdd9ed;
        transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
        border: 2px solid #4F1F9E;
        filter: drop-shadow(0 2px 0 #4F1F9E);
    }
    &:focus-within {
        & > span {
            color: #fff;
            /* background: #4F1F9E50; */
        }
    }
    
    ${props => props.shake && css`
        animation: ${shake} 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    `}
    
`

const StyledInput = styled.input`
    display: block;
    width: calc(100% - 32px);
    padding: 8px 16px;
    line-height: 25px;
    font-size: 16px;
    font-weight: 500;
    font-family: inherit;
    border-radius: 6px;
    -webkit-appearance: none;
    color: #99A3BA;
    border: 1px solid #CDD9ED;
    background: #fff;
    transition: border .3s ease;
    &::placeholder {
        color: #CBD1DC;
    }
    &:focus {
        outline: none;
    }
    border: 2px solid #4F1F9E;
    filter: drop-shadow(2px 2px 0 #4F1F9E);

    white-space: nowrap;
    display: block;
    &:not(:first-child):not(:last-child) {
        border-radius: 0;
    }
    &:first-child {
        border-radius: 6px 0 0 6px;
    }
    &:last-child {
        border-radius: 0 40px 40px 0;
    }
    &:not(:first-child) {
        margin-left: -1px;
    }

    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
`

const Border = styled.span`
    text-shadow: 3px 0px 0 #4F1F9E,2.837451725101904px 0.9740984076140504px 0 #4F1F9E,2.3674215281891806px 1.8426381380690033px 0 #4F1F9E,1.6408444743672808px 2.5114994347875856px 0 #4F1F9E,0.7364564614223977px 2.908200797817991px 0 #4F1F9E,-0.24773803641699682px 2.9897534790200098px 0 #4F1F9E,-1.2050862739589083px 2.747319979965172px 0 #4F1F9E,-2.0318447148772227px 2.2071717320193955px 0 #4F1F9E,-2.638421253619467px 1.427842179111221px 0 #4F1F9E,-2.959083910208167px 0.4937837708422021px 0 #4F1F9E,-2.959083910208167px -0.4937837708422014px 0 #4F1F9E,-2.6384212536194678px -1.4278421791112192px 0 #4F1F9E,-2.031844714877223px -2.2071717320193946px 0 #4F1F9E,-1.2050862739589072px -2.747319979965173px 0 #4F1F9E,-0.2477380364169982px -2.9897534790200098px 0 #4F1F9E,0.7364564614223964px -2.9082007978179916px 0 #4F1F9E,1.6408444743672796px -2.511499434787586px 0 #4F1F9E,2.3674215281891815px -1.842638138069002px 0 #4F1F9E,2.837451725101904px -0.9740984076140512px 0 #4F1F9E;
`

const BorderLink = styled(Link)`
    text-shadow: 3px 0px 0 #4F1F9E,2.837451725101904px 0.9740984076140504px 0 #4F1F9E,2.3674215281891806px 1.8426381380690033px 0 #4F1F9E,1.6408444743672808px 2.5114994347875856px 0 #4F1F9E,0.7364564614223977px 2.908200797817991px 0 #4F1F9E,-0.24773803641699682px 2.9897534790200098px 0 #4F1F9E,-1.2050862739589083px 2.747319979965172px 0 #4F1F9E,-2.0318447148772227px 2.2071717320193955px 0 #4F1F9E,-2.638421253619467px 1.427842179111221px 0 #4F1F9E,-2.959083910208167px 0.4937837708422021px 0 #4F1F9E,-2.959083910208167px -0.4937837708422014px 0 #4F1F9E,-2.6384212536194678px -1.4278421791112192px 0 #4F1F9E,-2.031844714877223px -2.2071717320193946px 0 #4F1F9E,-1.2050862739589072px -2.747319979965173px 0 #4F1F9E,-0.2477380364169982px -2.9897534790200098px 0 #4F1F9E,0.7364564614223964px -2.9082007978179916px 0 #4F1F9E,1.6408444743672796px -2.511499434787586px 0 #4F1F9E,2.3674215281891815px -1.842638138069002px 0 #4F1F9E,2.837451725101904px -0.9740984076140512px 0 #4F1F9E;
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    white-space: nowrap;
    margin-top: 20px;
    z-index: 10;
`

const Headphones = styled.img`
    width: 450px;
    height: 450px;
    z-index: 0;
`

const Disk = styled.img`
    width: 300px;
    height: 300px;
`

const CameraFrameContainer = styled.div`
  transform: translate(-50%, -50%);

  bottom: -50px;
  left: 50%;
`

const Flash = styled.div`
  position: absolute;
  background-color: ${props => props.flash ? "#fff" : "transparent"};
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  animation: ${props => props.flash ? "flash 0.5s" : ""};
  animation-fill-mode: forwards;
  transition: background-color 800ms 'ease-out';
`

const FrontCamera = styled.img`
  height: 20vh;
  /* transform: rotate(-15deg); */
`

export {
    Page,
    ImageBg,
    Logo,
    LogoShort,
    Border, 
    BorderLink,
    InputGroup, 
    FormGroup, 
    StyledInput,
    CameraFrameContainer,
    Disk, 
    Headphones,
    Flash,
    FrontCamera
}