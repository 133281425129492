import { motion as m} from "framer-motion";

import {
  Container,
  BackgroundImage,
  Play,
  Logo,
  Text,
  Button,
  Tickets
} from './styles';

// Assets
import background from '../../assets/images/bg_validacao.png';
import logo from '../../assets/images/logo.png';
import tickets from '../../assets/images/tickets.png'
import play from '../../assets/images/play1.png' 

function TicketInfo() {

  return(
    <m.div 
      initial={{opacity:0}} 
      animate={{opacity:1}} 
      transition={{duration:0.75}}
    >
      <BackgroundImage src={background}/>
      <Play src={play} />
      <Container>
        <Logo src={logo}/>

        <div>
          <Text style={{marginBottom: "0"}} className='t1'>{"Você é nosso convidado especial para o PRÉ EVENTO DE LANÇAMENTO da primeira edição do"}</Text>
          <Text style={{marginTop: "0"}}>{"<< FESTIVAL REPLAY >>"}</Text>
        </div>
        <Text>{"Contamos com a sua presença exclusiva no dia 27/07 na Scheeeins House a partir das 20h para essa viagem no tempo s2"}</Text>
        <Text>{"NOS VEMOS LÁ xD"}</Text>

        <Button to="/ticket">ACESSAR INGRESSO</Button>

        <Tickets src={tickets}/>
      </Container>
    </m.div>
  )
}

export default TicketInfo;