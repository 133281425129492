import React , { useEffect, useState } from 'react';

import {
	Routes,
	Route,
	useLocation,
} from "react-router-dom";

import { Container } from './styles';

// Pages
import Home from './pages/Home';
import TicketInfo from './pages/TicketInfo';
import Ticket from './pages/Ticket';

const App = () => {
	const location = useLocation();

	return (
		<Container>
			
			<Routes location={location} key={location.pathname}>
				<Route exact path="/" element={<Home />} />
				<Route exact path="/info" element={<TicketInfo />} />
				<Route exact path="/ticket" element={<Ticket />} />
			</Routes>
		</Container>
	);
}


export default App;
