import { 
  CameraFrame, 
  PicContainer, 
  Picture 
} from './styles'

function Camera({pic_url}) {

  return (
    <CameraFrame>
      <PicContainer>
        <Picture src={pic_url}/>
      </PicContainer>
    </CameraFrame>
  )
}

export default Camera