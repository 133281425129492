import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0);

  width: 80%;
  height: 90%;

  background-color: #8B4AE3;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  border-radius: 40px 40px 0 0;

  overflow: hidden;

  box-shadow: 2px 0px 5px -1px #000000;

  z-index: 1;
`

export const InfoContainer = styled.div`
  width: 100%;
  height: 65%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
`

export const BackgroundImage = styled.img`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: -5;
`

export const Tickets = styled.img`
  position: absolute;
  top: 10%;
  left: 50%;

  transform: translate(-50%, -50%);

  width: 50%;
  z-index: 2;
`

export const ShortLogo = styled.img`
  width: 30%;
`

export const Play = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;

  width: 100%;

  opacity: 0.2;

  transform: translate(-50%, -50%);

  z-index: -3;
`

export const Fire = styled.img`
  position: absolute;
  top: 50%;
  right: 0;

  height: 60%;

  opacity: 0.8;

  transform: translate(0, -50%);
  
  z-index: -3;
`

export const Title = styled.h1`
  font-size: 1.5rem;

  margin-bottom: 30px;

  text-shadow: 3px 0px 0 #4F1F9E,
  2.837451725101904px 0.9740984076140504px 0 #4F1F9E,
  2.3674215281891806px 1.8426381380690033px 0 #4F1F9E,
  1.6408444743672808px 2.5114994347875856px 0 #4F1F9E,
  0.7364564614223977px 2.908200797817991px 0 #4F1F9E,
  -0.24773803641699682px 2.9897534790200098px 0 #4F1F9E,
  -1.2050862739589083px 2.747319979965172px 0 #4F1F9E,
  -2.0318447148772227px 2.207171732019395px 0 #4F1F9E,
  -2.638421253619467px 1.427842179111221px 0 #4F1F9E,
  -2.959083910208167px 0.4937837708422021px 0 #4F1F9E,
  -2.959083910208167px -0.4937837708422014px 0 #4F1F9E,
  -2.6384212536194678px -1.4278421791112192px 0 #4F1F9E,
  -2.031844714877223px -2.2071717320193946px 0 #4F1F9E,
  -1.2050862739589072px -2.747319979965173px 0 #4F1F9E,
  -0.2477380364169982px -2.9897534790200098px 0 #4F1F9E,
  0.7364564614223964px -2.9082007978179916px 0 #4F1F9E,
  1.6408444743672796px -2.511499434787586px 0 #4F1F9E,
  2.3674215281891815px -1.842638138069002px 0 #4F1F9E,
  2.837451725101904px -0.9740984076140512px 0 #4F1F9E;
`

export const Text = styled.p`
  font-size: ${props => props.fontSize || "1.2rem"};
  font-family: Neuron;

  color: ${props => props.color || "#fff"};

  margin: 0;
  margin-right: 5px;
`

export const TextInfoContainer = styled.div`
  width: 100%;
  height: 100%;

  padding-left: 7%;
`

export const TextContainer = styled.div`
  width: 100%;

  margin-top: 5%;

  display: flex;
`

export const TicketContainer = styled.div`
  width: 100%;
  height: 25%;

  background-color: #fff;

  border-radius: 15px 15px 0 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const BarcodeContainer = styled.div`
  width: 100%;
  height: 80%;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

export const TextBarCode = styled.p`
  position: absolute;
  bottom: 0;

  margin: 0;

  font-size: 1.2rem;
  background-color: #fff;
  color: #000;

  padding: 0px 10px;

`

export const TitleContainer = styled.div`
  position: relative;
`

export const AccentE = styled.p`
  position: absolute;

  top: -10px;
  left: 55px;

  font-size: 1.5rem;

  margin: 0;

  text-shadow: 3px 0px 0 #4F1F9E,
  2.837451725101904px 0.9740984076140504px 0 #4F1F9E,
  2.3674215281891806px 1.8426381380690033px 0 #4F1F9E,
  1.6408444743672808px 2.5114994347875856px 0 #4F1F9E,
  0.7364564614223977px 2.908200797817991px 0 #4F1F9E,
  -0.24773803641699682px 2.9897534790200098px 0 #4F1F9E,
  -1.2050862739589083px 2.747319979965172px 0 #4F1F9E,
  -2.0318447148772227px 2.207171732019395px 0 #4F1F9E,
  -2.638421253619467px 1.427842179111221px 0 #4F1F9E,
  -2.959083910208167px 0.4937837708422021px 0 #4F1F9E,
  -2.959083910208167px -0.4937837708422014px 0 #4F1F9E,
  -2.6384212536194678px -1.4278421791112192px 0 #4F1F9E,
  -2.031844714877223px -2.2071717320193946px 0 #4F1F9E,
  -1.2050862739589072px -2.747319979965173px 0 #4F1F9E,
  -0.2477380364169982px -2.9897534790200098px 0 #4F1F9E,
  0.7364564614223964px -2.9082007978179916px 0 #4F1F9E,
  1.6408444743672796px -2.511499434787586px 0 #4F1F9E,
  2.3674215281891815px -1.842638138069002px 0 #4F1F9E,
  2.837451725101904px -0.9740984076140512px 0 #4F1F9E;
`

export const AccentC = styled.p`
  position: absolute;

  top: 55px;
  left: 67px;

  font-size: 1.5rem;

  margin: 0;

  text-shadow: 3px 0px 0 #4F1F9E,
  2.837451725101904px 0.9740984076140504px 0 #4F1F9E,
  2.3674215281891806px 1.8426381380690033px 0 #4F1F9E,
  1.6408444743672808px 2.5114994347875856px 0 #4F1F9E,
  0.7364564614223977px 2.908200797817991px 0 #4F1F9E,
  -0.24773803641699682px 2.9897534790200098px 0 #4F1F9E,
  -1.2050862739589083px 2.747319979965172px 0 #4F1F9E,
  -2.0318447148772227px 2.207171732019395px 0 #4F1F9E,
  -2.638421253619467px 1.427842179111221px 0 #4F1F9E,
  -2.959083910208167px 0.4937837708422021px 0 #4F1F9E,
  -2.959083910208167px -0.4937837708422014px 0 #4F1F9E,
  -2.6384212536194678px -1.4278421791112192px 0 #4F1F9E,
  -2.031844714877223px -2.2071717320193946px 0 #4F1F9E,
  -1.2050862739589072px -2.747319979965173px 0 #4F1F9E,
  -0.2477380364169982px -2.9897534790200098px 0 #4F1F9E,
  0.7364564614223964px -2.9082007978179916px 0 #4F1F9E,
  1.6408444743672796px -2.511499434787586px 0 #4F1F9E,
  2.3674215281891815px -1.842638138069002px 0 #4F1F9E,
  2.837451725101904px -0.9740984076140512px 0 #4F1F9E
`